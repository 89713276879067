import { Component, ViewChild, ChangeDetectorRef, AfterViewInit, ɵConsole, Renderer2, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { LinkFormComponent } from './content/pages/link/form/link-form.component';
import { PaymentFormComponent } from './content/pages/payment/form/payment-form.component';
import { MessageService } from './services/message.service';
import { Transaction } from './models/transaction.model';
import { PaymentResponseFormComponent } from './content/pages/payment-response/form/payment-response-form.component';
import { LandingFormComponent } from './content/pages/landing/form/landing-form.component';
import { OrderService } from './services/order.service';
import { ErrorFormComponent } from './content/pages/error/form/error-form.component';
import { DOCUMENT } from '@angular/common';
import { Entity } from './models/entity.model';
import { VisualLinkService } from './services/visual-link.service';
import { environment } from 'src/environments/environment';
import { FooterFormComponent } from './content/pages/footer/form/footer-form.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  entity: Entity;

  widgetId = 'synap-landing-form';
  title = 'Synapsis-Link';
  transaction: Transaction;
  regexpIdentifier = new RegExp('^([a-zA-Z0-9,-.]{3,})*$');

  subscription: Subscription;
  @ViewChild('childLanding', { static: false }) childLanding: LandingFormComponent;
  @ViewChild('childLink', { static: false }) childLink: LinkFormComponent;
  @ViewChild('childPayment', { static: false }) childPayment: PaymentFormComponent;
  @ViewChild('childPaymentResponse', { static: false }) childPaymentResponse: PaymentResponseFormComponent;
  @ViewChild('childError', { static: false }) childError: ErrorFormComponent;
  @ViewChild('childFooter') childFooter: FooterFormComponent;

  constructor(private messageService: MessageService,
    private orderService: OrderService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: any,
    private visualLinkService: VisualLinkService) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message) {
        switch (message.name) {
          case LinkFormComponent.EVENT_GET_LINK:
            this.onGetLink(message.parameter);
            break;
          case LinkFormComponent.EVENT_SET_PAYMENT:
            this.onSetPayment(message.parameter);
            break;
          case PaymentFormComponent.EVENT_SET_RESPONSE:
            this.onSetResponse(message.parameter);
            break;
          case PaymentFormComponent.EVENT_SET_ERROR:
            this.onSetError();
            break;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.reset();
    const indexPaymentMethod = window.location.search.indexOf('?paymentMethod=')
    const txtPaymentMethod = indexPaymentMethod == 0 ? window.location.search.substring(indexPaymentMethod + 15, 19) : 'LINK';

    if (txtPaymentMethod == 'LINK') {
      const index = window.location.search.indexOf('?identifier=')
      const txtIdentifier = index == 0 ? window.location.search.substring(index + 12) : '';

      const validIdentifier = this.regexpIdentifier.test(txtIdentifier)

      if (validIdentifier) {
        await this.getOrder(txtIdentifier);
      }
      else {
        console.info('ROUTING TO ERROR PAGE');
        this.widgetId = 'synap-error-form';
      }
      this.cdr.detectChanges();
    }
    else if (txtPaymentMethod == 'BANK') {
      const index = window.location.search.indexOf('&identifier=')
      const webReference = index == 19 ? window.location.search.substring(index + 12) : '';

      const validIdentifier = this.regexpIdentifier.test(webReference)

      if (validIdentifier) {
        //this.getLinkBank('KASHIO', txtIdentifier);
        this.onSetPaymentBankWebReference(webReference);
      }
      else {
        console.info('ROUNTING TO ERROR PAGE');
        this.widgetId = 'synap-error-form';
      }
      this.cdr.detectChanges();
    }
    else {
      console.info('ROUNTING TO ERROR PAGE');

      this.widgetId = 'synap-error-form';
      this.cdr.detectChanges();
    }
  }

  async getOrder(orderIdentifier: string) {
    // this.orderService.getOrder(orderIdentifier).subscribe(
    //   response => {
    let response = await this.orderService.getOrder(orderIdentifier).toPromise();
    if (response && response.body.success) {
      this.transaction = response.body.data;

      const processor = this.transaction.settings.procesors?.find(processor => processor.code == 'KUSHKI');
      const actionFooter = processor && processor.code == 'KUSHKI' ? 'ADD' : 'NONE';
      this.childFooter.setFooterProcessor(actionFooter, processor);

      this.setStyles(this.transaction.order.entity);

      const date: Date = new Date();
      const expiration: Date = new Date(this.transaction.order.expiration);

      if (this.transaction.order.state.code == 'AUTHORIZED') {
        const responsePayed = {
          message: {
            code: 'orderAlreadyAuthorized',
            text: 'Lo sentimos, la orden ya fue pagada previamente'
          }
        }
        const data = {
          response: responsePayed,
          transaction: this.transaction
        }
        this.onSetResponse(data);
      }
      else if (this.transaction.order.state.code == 'REVERSED') {
        const responsePayed = {
          message: {
            code: 'orderAlreadyReversed',
            text: 'Lo sentimos, la orden fue anulada'
          }
        }
        const data = {
          response: responsePayed,
          transaction: this.transaction
        }
        this.onSetResponse(data);
      }
      else if (expiration < date) {
        const responsePayed = {
          message: {
            code: 'orderHasExpired',
            text: 'Lo sentimos, la orden ha expirado'
          }
        }
        const data = {
          response: responsePayed,
          transaction: this.transaction
        }
        this.onSetResponse(data);
      }
      else {
        this.onGetLink(this.transaction);
      }
      this.cdr.detectChanges();
    }
    else {
      console.log(response);
      this.widgetId = 'synap-error-form';
    }
    //   }//,
    //   error => {
    //     console.log(error);
    //     this.widgetId = 'synap-error-form';
    //   }
    // );
  }

  onGetLink(transaction: Transaction) {
    this.widgetId = 'synap-link-form';
    this.childLink.setOrder(transaction);
  }

  onSetPaymentBankWebReference(webReference: string) {
    this.widgetId = 'synap-payment-form';

    const htmlIsIncluded = webReference.includes('.html');
    const processor = {
      code: (htmlIsIncluded ? 'PAGOEFECTIVO' : 'KASHIO')
    }

    switch (processor.code) {
      case 'KASHIO':
        this.childPayment.startPaymentBank(null, processor, environment.GATEWAY_KASHIO, webReference);
        break;
      case 'PAGOEFECTIVO':
        this.childPayment.startPaymentBank(null, processor, environment.GATEWAY_PAGOEFECTIVO, webReference);
        break;
    }
  }

  onSetPayment(parameter: any) {
    this.widgetId = 'synap-payment-form';
    this.childPayment.setPayment(parameter.authentication, parameter.transaction, parameter.processor);
  }

  onSetResponse(data) {
    this.widgetId = 'synap-payment-response-form';
    this.childPaymentResponse.setResponse(data);
  }

  onSetError() {
    this.widgetId = 'synap-error-form';
  }

  setStyles(entity) {
    let visualLink = this.visualLinkService.setVisualLink(entity);
    this.injectStyle(visualLink);
  }

  private injectStyle(visualLink) {
    const styles = this.document.createElement('STYLE') as HTMLStyleElement;
    styles.id = 'dynamic-css';
    styles.innerHTML = this.visualLinkService.getVisualLinkStyle(visualLink);
    this.renderer.appendChild(this.document.head, styles);
  }

  reset() {
    this.widgetId = 'synap-landing-form';
  }

  public ngOnDestroy() {
    const styles = this.document.getElementById('dynamic-css') as HTMLElement;
    if (styles && styles.parentElement) {
      (styles.parentElement as any).removeChild(styles);
    }
  }
}
