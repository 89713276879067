import { Component, OnInit } from '@angular/core';
import { Processor } from 'src/app/models/processor.models';
import { Transaction } from 'src/app/models/transaction.model';
import { MessageService } from 'src/app/services/message.service';

declare const printResponse: any;

@Component({
  selector: 'app-payment-response-form',
  templateUrl: './payment-response-form.component.html',
  styleUrls: ['./payment-response-form.component.css']
})
export class PaymentResponseFormComponent implements OnInit {
  response: any;
  authentication: any;
  transaction: Transaction;
  processor: Processor;
  isAuthorized: boolean = false;
  //isAlreadyAuthorized: boolean = false;
  isRePayable: boolean = false;

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
  }

  setPaymentAlready(transaction: Transaction) {
    this.transaction = transaction;
  }

  setResponse(data) {
    this.response = data.response;
    this.authentication = data.authentication;
    this.transaction = data.transaction;
    this.processor = data.processor;

    // console.log('RESPONSE', this.response);

    if (this.transaction.order.targetType.code == 'MULTIPLE') {
      this.transaction.order.uniqueIdentifier = this.response.order.uniqueIdentifier;
      this.transaction.order.number = this.response.order.number;
    }
    //this.isAlreadyAuthorized = this.response.message.code == 'orderAlreadyAuthorized' ? true : false;
    this.isRePayable = this.response.success && !this.response.result.accepted ? true : false;
    this.isAuthorized = this.response.success && this.response.result.accepted ? true : false;
  }

  getResponseTxt(response) {
    const responseTxt = ( (response.success && response.result.accepted) ? 'Felicitaciones, tu pago ha sido exitoso' 
                      : ( (response.success && !response.result.accepted) ? 'Lo sentimos, tu pago ha sido rechazado' 
                      : response.message.text ) );
    // console.log(responseTxt);
    return responseTxt;
  }

  retryPayment() {
    // this.messageService.sendMessage('SET_PAYMENT', this.transaction);
    this.messageService.sendMessage('SET_PAYMENT', { authentication: this.authentication, transaction: this.transaction, processor: this.processor });
  }

  printResponse() {
    window.print();
  }
}
